import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Star, ArrowRight, ArrowLeft, TrendingUp, Award, Building2, Store, Music, Home, Download } from 'lucide-react';
import CinematicSlide from '../components/CinematicSlide';
import DynamicText from '../components/DynamicText';
import GrowthTimeline from '../components/GrowthTimeline';
import USMap from '../components/USMap';
import RevenueProjections from '../components/RevenueProjections';
import SkydanceSlide from '../components/SkydanceSlide';
import FinancialModelSlide from '../components/FinancialModelSlide';
import WesternCultureSlide from '../components/WesternCultureSlide';
import BestOfBothSlide from '../components/BestOfBothSlide';
import RodeoBusinessSlide from '../components/RodeoBusinessSlide';
import NashvilleArtistsSlide from '../components/NashvilleArtistsSlide';
import NashvilleLegacySlide from '../components/NashvilleLegacySlide';
import RodeoMusicSlide from '../components/RodeoMusicSlide';
import NashvilleRodeoSlide from '../components/NashvilleRodeoSlide';
import PersonalLetterSlide from '../components/PersonalLetterSlide';

// Animation variants for slide transitions
const slideVariants = {
  enter: (direction) => ({
    x: direction > 0 ? 1000 : -1000,
    opacity: 0
  }),
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction) => ({
    zIndex: 0,
    x: direction < 0 ? 1000 : -1000,
    opacity: 0
  })
};

// Common transition settings
const slideTransition = {
  x: { type: "spring", stiffness: 300, damping: 30 },
  opacity: { duration: 0.4 }
};

const allSlides = [
  {
    id: 'opening',
    title: 'Cinematic Introduction',
    component: ({ skipAnimations, currentSubStep }) => (
      <CinematicSlide 
        backgroundUrl="/images/1940s/1940.png"
        className="bg-black"
        variants={slideVariants}
        transition={slideTransition}
      >
        <div className="flex flex-col justify-center items-center h-full">
          <div className="text-center w-full px-4 sm:px-8">
            <motion.div
              initial={skipAnimations ? { opacity: 1 } : { opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: skipAnimations ? 0.3 : 1.5 }}
              className="space-y-6 sm:space-y-8 font-avenir"
            >
              <DynamicText>
                <div className="flex flex-wrap justify-center items-center gap-2 sm:gap-4">
                  <span className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-white font-light tracking-wide">FOR OVER</span>
                  <span className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-red-600 font-bold tracking-wide">80</span>
                  <span className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-white font-light tracking-wide">YEARS</span>
                </div>
              </DynamicText>
              
              <DynamicText delay={skipAnimations ? 0 : 800}>
                <div className="flex flex-wrap justify-center items-center gap-2 sm:gap-4">
                  <span className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl text-red-600 font-bold tracking-wide">COUNTRY MUSIC</span>
                  <span className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl text-white font-light tracking-wide">AND</span>
                  <span className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl text-red-600 font-bold tracking-wide">RODEO</span>
                </div>
              </DynamicText>

              <DynamicText delay={skipAnimations ? 0 : 1600}>
                <div className="flex flex-wrap justify-center items-center gap-2 sm:gap-4">
                  <span className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-white font-light tracking-wide">
                    HAVE SHARED
                  </span>
                  <span className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-red-600 font-bold tracking-wide">
                    ONE SOUL
                  </span>
                </div>
              </DynamicText>
            </motion.div>
          </div>
        </div>
      </CinematicSlide>
    )
  },
  {
    id: 'except',
    title: 'Nashville Exception',
    component: ({ skipAnimations }) => (
      <CinematicSlide className="bg-black">
        <div className="flex flex-col justify-center items-center h-full">
          <div className="text-center w-full px-4 sm:px-8">
            <motion.div
              initial={skipAnimations ? { opacity: 1 } : { opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
              className="space-y-8 sm:space-y-12 font-avenir"
            >
              <DynamicText>
                <p className="text-2xl sm:text-3xl md:text-4xl text-white/80 font-light tracking-wide max-w-3xl mx-auto">
                  Except in the city where country music calls home.
                </p>
              </DynamicText>
              
              <DynamicText delay={skipAnimations ? 0 : 3000}>
                <p className="text-4xl sm:text-5xl md:text-6xl text-red-600 font-bold tracking-widest">
                  UNTIL NOW
                </p>
              </DynamicText>
            </motion.div>
          </div>
        </div>
      </CinematicSlide>
    )
  },
  {
    id: 'event-intro',
    title: 'Event Introduction',
    component: ({ skipAnimations }) => (
      <CinematicSlide 
        backgroundUrl="/images/nashville/broadway.jpg"
        className="bg-black"
      >
        <div className="flex flex-col justify-center items-center h-full">
          <div className="text-center w-full px-4 sm:px-8">
            <motion.div
              initial={skipAnimations ? { opacity: 1 } : { opacity: 0 }}
              animate={{ opacity: 1 }}
              className="space-y-8 sm:space-y-12 font-avenir"
            >
              <DynamicText>
                <p className="text-3xl sm:text-4xl md:text-5xl text-white font-bold tracking-widest drop-shadow-lg">
                  MAY 29-31, 2025
                </p>
              </DynamicText>
              
              <DynamicText delay={skipAnimations ? 0 : 1000}>
                <div className="space-y-4 sm:space-y-6">
                  <p className="text-2xl sm:text-3xl md:text-4xl text-white/90 font-light tracking-wide drop-shadow-lg">
                    THE SOUL OF COUNTRY MUSIC
                  </p>
                  <p className="text-2xl sm:text-3xl md:text-4xl text-white/90 font-light tracking-wide drop-shadow-lg">
                    MEETS THE SPIRIT OF THE RODEO
                  </p>
                </div>
              </DynamicText>

              <DynamicText delay={skipAnimations ? 0 : 2000}>
                <div className="space-y-2">
                  <p className="text-xl sm:text-2xl md:text-3xl text-white/80 font-light tracking-wide drop-shadow-lg">
                    TO BRING
                  </p>
                  <div className="space-y-1">
                    <p className="text-2xl sm:text-3xl md:text-5xl text-red-600 font-bold tracking-widest drop-shadow-lg bg-black/30 py-2">
                      NASHVILLE'S FIRST
                    </p>
                    <p className="text-2xl sm:text-3xl md:text-5xl text-red-600 font-bold tracking-widest drop-shadow-lg bg-black/30 py-2">
                      PRCA RODEO
                    </p>
                  </div>
                </div>
              </DynamicText>
            </motion.div>
          </div>
        </div>
      </CinematicSlide>
    )
  },
  {
    id: 'logo-reveal',
    title: 'Logo Reveal',
    component: ({ skipAnimations }) => (
      <CinematicSlide className="bg-black">
        {/* Background Image with Overlays */}
        <div className="absolute inset-0">
          <img 
            src="/images/nashville/Rodeo.png"
            alt="Rodeo Background"
            className="w-full h-full object-cover opacity-40"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/40 to-black/60" />
        </div>
        
        <div className="relative h-full flex flex-col items-center justify-center px-4 sm:px-8">
          {/* Decorative Elements */}
          <motion.div
            initial={skipAnimations ? {} : { opacity: 0 }}
            animate={{ opacity: 0.3 }}
            transition={{ duration: 2 }}
            className="absolute inset-0 pointer-events-none"
          >
            <div className="absolute top-0 left-0 w-full h-32 bg-gradient-to-b from-red-600/10 to-transparent" />
            <div className="absolute bottom-0 left-0 w-full h-32 bg-gradient-to-t from-red-600/10 to-transparent" />
          </motion.div>

          {/* Center Container */}
          <div className="container mx-auto">
            <div className="max-w-5xl mx-auto flex flex-col md:flex-row items-center justify-center gap-6 md:gap-8">
              {/* Logo Section */}
              <motion.div 
                className="relative flex-shrink-0 md:pr-2 w-[180px] sm:w-[220px] md:w-[240px] lg:w-[280px]"
                initial={skipAnimations ? {} : { scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: 1.8, ease: [0.21, 0.53, 0.29, 0.99] }}
              >
                {/* Glow Effect */}
                <div className="absolute inset-0 blur-2xl bg-red-600/20 scale-110" />
                
                <img 
                  src="/images/logo/mcr_logo.png" 
                  alt="Music City Rodeo Logo" 
                  className="relative z-10 w-full h-auto drop-shadow-2xl"
                />
              </motion.div>

              {/* Info Section */}
              <div className="flex-1 space-y-4 text-center md:text-left max-w-lg">
                {/* Date */}
                <motion.div
                  initial={skipAnimations ? {} : { opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.8, duration: 1 }}
                  className="space-y-2"
                >
                  <div className="flex items-center justify-center md:justify-start gap-3">
                    <span className="hidden md:block w-12 h-px bg-red-600/50" />
                    <p className="text-xl sm:text-2xl md:text-3xl text-white font-light tracking-[0.3em]">
                      MAY 29 • 30 • 31
                    </p>
                  </div>
                  <p className="text-lg sm:text-xl text-white/60 font-light tracking-[0.2em] md:pl-16">
                    2025
                  </p>
                </motion.div>

                {/* Location */}
                <motion.div
                  initial={skipAnimations ? {} : { opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 1.2, duration: 1 }}
                  className="space-y-2"
                >
                  <p className="text-xl sm:text-2xl md:text-3xl text-white font-light tracking-[0.2em]">
                    BRIDGESTONE ARENA
                  </p>
                  <p className="text-xl sm:text-2xl md:text-3xl tracking-[0.2em]">
                    <span className="text-red-600 font-medium">NASHVILLE</span>
                    <span className="text-white">, TENNESSEE</span>
                  </p>
                </motion.div>

                {/* Presenter */}
                <motion.div
                  initial={skipAnimations ? {} : { opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 1.6, duration: 1 }}
                  className="space-y-2"
                >
                  <p className="text-xs sm:text-sm text-white/60 tracking-[0.3em] uppercase">
                    Presented By
                  </p>
                  <p className="text-lg sm:text-xl md:text-2xl text-red-600 font-medium tracking-[0.15em]">
                    TIM McGRAW AND DOWN HOME
                  </p>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </CinematicSlide>
    )
  },
  {
    id: 'invitation',
    title: 'Special Invitation',
    component: ({ skipAnimations }) => (
      <CinematicSlide className="bg-black">
        <div className="flex flex-col justify-center items-center h-full">
          <div className="text-center w-full px-4 sm:px-8">
            <motion.div
              initial={skipAnimations ? { opacity: 1 } : { opacity: 0 }}
              animate={{ opacity: 1 }}
              className="space-y-8 sm:space-y-12 font-avenir"
            >
              <DynamicText skipAnimation={skipAnimations}>
                <p className="text-xl sm:text-2xl md:text-3xl text-white/90 font-light tracking-wide leading-relaxed max-w-4xl mx-auto">
                  To properly bring the sport of Rodeo to the home of Country Music, we are inviting a carefully chosen few who embody its spirit to partner with us.
                </p>
              </DynamicText>
              
              <DynamicText delay={skipAnimations ? 0 : 2000} skipAnimation={skipAnimations}>
                <p className="text-2xl sm:text-3xl md:text-4xl text-red-600 font-medium tracking-wide max-w-3xl mx-auto">
                  Reba McEntire, a true crown jewel, leads this extraordinary group.
                </p>
              </DynamicText>
            </motion.div>
          </div>
        </div>
      </CinematicSlide>
    )
  },
  {
    id: 'oklahoma-legacy',
    title: 'Oklahoma Legacy',
    component: ({ skipAnimations }) => (
      <CinematicSlide className="bg-black">
        <div className="absolute inset-0">
          <motion.div 
            className="h-full w-full"
            initial={{ filter: "grayscale(100%) brightness(40%)" }}
            animate={{ filter: "grayscale(0%) brightness(100%)" }}
            transition={{ duration: 3, delay: 1.5, ease: "easeInOut" }}
          >
            <img 
              src="/images/landscapes/oklahoma-sunset.jpg"
              alt="Oklahoma Sunset"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/50 to-transparent" />
          </motion.div>
        </div>

        <div className="relative z-10 flex flex-col justify-center items-center h-full">
          <div className="text-center w-full px-4 sm:px-8">
            <motion.div className="space-y-6 sm:space-y-8 font-avenir">
              <DynamicText delay={skipAnimations ? 0 : 1500}>
                <div className="flex flex-wrap justify-center items-center gap-2 sm:gap-4">
                  <span className="text-2xl sm:text-3xl md:text-4xl text-white font-light tracking-[0.25em]">
                    ON THIS LAND
                  </span>
                </div>
              </DynamicText>
              
              <DynamicText delay={skipAnimations ? 0 : 3000}>
                <div className="flex flex-wrap justify-center items-center gap-2 sm:gap-4">
                  <span className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-white font-bold tracking-[0.25em]">
                    RESILIENCE WAS BORN
                  </span>
                </div>
              </DynamicText>

              <DynamicText delay={skipAnimations ? 0 : 4500}>
                <div className="flex flex-col gap-2 sm:gap-4">
                  <p className="text-lg sm:text-xl md:text-2xl text-white/90 font-light tracking-[0.15em]">
                    WHERE THE SUN RISES OVER 7,000 ACRES OF HARD WORK,
                  </p>
                  <p className="text-xl sm:text-2xl md:text-3xl text-red-600 font-bold tracking-[0.15em]">
                    THE McENTIRE FAMILY BUILT THEIR LEGACY
                  </p>
                </div>
              </DynamicText>
            </motion.div>
          </div>
        </div>
      </CinematicSlide>
    )
  },
  {
    id: 'clark-legacy',
    title: 'Clark Legacy',
    component: ({ skipAnimations, currentSubStep = 0 }) => (
      <CinematicSlide className="bg-black">
        <div className="absolute inset-0">
          <div className="grid grid-cols-2 sm:grid-cols-3 h-full gap-2 p-2 sm:p-4">
            {[
              { src: "/images/Family/CMcEntire1.jpg", delay: 0.3 },
              { src: "/images/Family/53d8003d2f50a.image.jpg", delay: 0.6 },
              { src: "/images/Family/McEntire_Clark1-241x300.jpg", delay: 0.9 },
              { src: "/images/Family/137692297_cc71c2e9-47dc-4832-8db6-df6ab8cf75da.jpeg", delay: 1.2 },
              { src: "/images/Family/426648946_901506701637974_4652965490975321981_n.jpg", delay: 1.5 },
              { src: "/images/Family/images.jpeg", delay: 1.8 }
            ].map((photo, index) => (
              <motion.div
                key={photo.src}
                initial={skipAnimations ? { opacity: 1, x: 0 } : { opacity: 0, x: index % 2 === 0 ? -20 : 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: skipAnimations ? 0.3 : 1.5, delay: skipAnimations ? 0 : photo.delay }}
                className="relative overflow-hidden h-full"
              >
                <img 
                  src={photo.src}
                  alt="Clark McEntire"
                  className="w-full h-full object-cover grayscale"
                />
                <div className="absolute inset-0 bg-gradient-to-r from-black/80 to-black/40" />
              </motion.div>
            ))}
          </div>
          <div className="absolute inset-0 bg-gradient-to-t from-black via-black/70 to-transparent" />
        </div>

        <div className="relative z-10 flex flex-col justify-center items-center h-full">
          <div className="text-center w-full px-4 sm:px-8">
            <motion.div
              initial={skipAnimations ? { opacity: 1 } : { opacity: 0 }}
              animate={{ opacity: 1 }}
              className="space-y-8 sm:space-y-16 font-avenir"
            >
              <DynamicText delay={skipAnimations ? 0 : 2000} skipAnimation={skipAnimations}>
                <div className="space-y-4">
                  <p className="text-3xl sm:text-4xl md:text-5xl text-white font-bold tracking-wide drop-shadow-[0_4px_4px_rgba(0,0,0,0.8)]">
                    CLARK McENTIRE
                  </p>
                  <p className="text-xl sm:text-2xl md:text-3xl text-red-600 font-extrabold tracking-wide drop-shadow-[0_4px_4px_rgba(0,0,0,0.8)]">
                    THREE-TIME WORLD CHAMPION STEER ROPER
                  </p>
                </div>
              </DynamicText>

              <DynamicText delay={skipAnimations ? 0 : 3000} skipAnimation={skipAnimations}>
                <p className="text-xl sm:text-2xl md:text-3xl text-white font-bold tracking-wide drop-shadow-[0_4px_4px_rgba(0,0,0,0.8)] leading-relaxed italic">
                  "instilled in his family the grit and determination of the rodeo"
                </p>
              </DynamicText>
            </motion.div>
          </div>
        </div>
      </CinematicSlide>
    )
  },
  {
    id: 'young-reba',
    title: 'Young Reba',
    component: ({ skipAnimations }) => (
      <CinematicSlide className="bg-black">
        <div className="absolute inset-0">
          <motion.div
            initial={skipAnimations ? { scale: 1, opacity: 1 } : { scale: 1.05, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 2, ease: [0.45, 0.05, 0.55, 0.95] }}
            className="relative h-full"
          >
            <img 
              src="/images/Family/reba-barrel.png"
              alt="Young Reba Barrel Racing"
              className="w-full h-full object-cover opacity-90"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black via-black/40 to-transparent" />
          </motion.div>
        </div>

        <div className="relative z-10 flex flex-col justify-center items-center h-full">
          <div className="text-center w-full px-4 sm:px-8">
            <motion.div
              initial={skipAnimations ? { opacity: 1 } : { opacity: 0 }}
              animate={{ opacity: 1 }}
              className="space-y-8 sm:space-y-16 font-avenir"
            >
              <DynamicText delay={skipAnimations ? 0 : 1000} skipAnimation={skipAnimations}>
                <p className="text-2xl sm:text-3xl md:text-4xl text-white font-light tracking-wide drop-shadow-lg">
                  Before the stages, there were arenas.
                </p>
              </DynamicText>
              
              <DynamicText delay={skipAnimations ? 0 : 2500} skipAnimation={skipAnimations}>
                <p className="text-2xl sm:text-3xl md:text-4xl text-white font-light tracking-wide drop-shadow-lg">
                  Before the spotlight, there were barrels.
                </p>
              </DynamicText>

              <DynamicText delay={skipAnimations ? 0 : 4000} skipAnimation={skipAnimations}>
                <p className="text-3xl sm:text-4xl md:text-5xl text-red-600 font-medium tracking-wide drop-shadow-lg">
                  Reba lived the cowgirl life.
                </p>
              </DynamicText>
            </motion.div>
          </div>
        </div>
      </CinematicSlide>
    )
  },
  {
    id: 'nfr-1974',
    title: 'NFR 1974',
    component: ({ skipAnimations }) => (
      <CinematicSlide className="bg-black">
        <div className="absolute inset-0">
          <motion.div
            initial={skipAnimations ? { opacity: 1 } : { opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
            className="relative h-full"
          >
            <img 
              src="/images/Family/2-Reba_McEntire_750.jpg"
              alt="Young Reba McEntire at the rodeo"
              className="w-full h-full object-cover opacity-80 object-[center_25%]"
            />
            <div className="absolute inset-0 bg-black/20" />
            <div className="absolute inset-0" 
                 style={{ 
                   background: 'linear-gradient(to top, black 0%, rgba(0,0,0,0.7) 20%, transparent 50%)'
                 }}
            />
          </motion.div>
        </div>

        <div 
          className="fixed left-0 right-0 text-center px-4 sm:px-8"
          style={{ bottom: '96px' }}
        >
          <motion.div
            initial={skipAnimations ? { opacity: 1 } : { opacity: 0 }}
            animate={{ opacity: 1 }}
            className="space-y-3 max-w-5xl mx-auto"
          >
            <DynamicText skipAnimation={skipAnimations}>
              <p className="text-2xl sm:text-3xl md:text-4xl text-white font-light tracking-[0.05em] drop-shadow-lg">
                Her voice soared over the rodeo crowd,
              </p>
            </DynamicText>

            <DynamicText delay={skipAnimations ? 0 : 800} skipAnimation={skipAnimations}>
              <p className="text-2xl sm:text-3xl md:text-4xl text-red-600 font-bold tracking-[0.05em] drop-shadow-lg">
                capturing the hearts of those who knew her as one of their own.
              </p>
            </DynamicText>
          </motion.div>
        </div>
      </CinematicSlide>
    )
  },
  {
    id: 'homecoming',
    title: 'Homecoming',
    component: ({ skipAnimations }) => (
      <CinematicSlide className="bg-black">
        <div className="absolute inset-0">
          <motion.div
            initial={skipAnimations ? { opacity: 1 } : { opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
            className="relative h-full"
          >
            <img 
              src="/images/Family/reba4.jpg"
              alt="Reba McEntire Portrait"
              className="w-full h-full object-cover opacity-80 object-[center_25%]"
            />
            <div className="absolute inset-0 bg-black/20" />
            <div className="absolute inset-0" 
                 style={{ 
                   background: 'linear-gradient(to top, black 0%, rgba(0,0,0,0.7) 20%, transparent 50%)'
                 }}
            />
          </motion.div>
        </div>

        <div 
          className="fixed left-0 right-0 text-center px-4 sm:px-8"
          style={{ bottom: '96px' }}
        >
          <motion.div
            initial={skipAnimations ? { opacity: 1 } : { opacity: 0 }}
            animate={{ opacity: 1 }}
            className="space-y-3 max-w-5xl mx-auto"
          >
            <DynamicText skipAnimation={skipAnimations}>
              <p className="text-2xl sm:text-3xl md:text-4xl text-white font-light tracking-[0.05em] drop-shadow-lg">
                FOR THE GIRL WHO GREW UP
              </p>
            </DynamicText>

            <DynamicText delay={skipAnimations ? 0 : 800}>
              <p className="text-2xl sm:text-3xl md:text-4xl text-red-600 font-bold tracking-[0.05em] drop-shadow-lg">
                BORROWING SADDLES AND RIDING ROPING HORSES,
              </p>
            </DynamicText>

            <DynamicText delay={skipAnimations ? 0 : 1600}>
              <div className="space-y-1">
                <p className="text-2xl sm:text-3xl md:text-4xl text-white font-light tracking-[0.05em] drop-shadow-lg">
                  THIS MOMENT WAS MORE THAN A GIFT
                </p>
                <p className="text-2xl sm:text-3xl md:text-4xl text-red-600 font-bold tracking-[0.05em] drop-shadow-lg">
                  IT WAS A HOMECOMING.
                </p>
              </div>
            </DynamicText>
          </motion.div>
        </div>
      </CinematicSlide>
    )
  },
  {
    id: 'authenticity',
    title: 'Authenticity',
    component: ({ skipAnimations }) => (
      <CinematicSlide className="bg-black">
        <div className="absolute inset-0">
          <motion.div
            initial={skipAnimations ? { opacity: 1 } : { opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
            className="relative h-full"
          >
            <img 
              src="/images/career/beauty.jpg"
              alt="Reba McEntire Portrait"
              className="w-full h-full object-cover opacity-80 object-[40%_25%] md:object-[center_25%]"
            />
            <div className="absolute inset-0 bg-black/20" />
            <div className="absolute inset-0" 
                 style={{ 
                   background: 'linear-gradient(to top, black 0%, rgba(0,0,0,0.7) 20%, transparent 50%)'
                 }}
            />
          </motion.div>
        </div>

        <div 
          className="fixed left-0 right-0 text-center px-4 sm:px-8"
          style={{ bottom: '96px' }}
        >
          <motion.div
            initial={skipAnimations ? { opacity: 1 } : { opacity: 0 }}
            animate={{ opacity: 1 }}
            className="space-y-3 max-w-5xl mx-auto"
          >
            <DynamicText skipAnimation={skipAnimations}>
              <p className="text-2xl sm:text-3xl md:text-4xl text-white font-light tracking-[0.05em] drop-shadow-lg">
                NO ONE BRIDGES THESE WORLDS QUITE LIKE HER
              </p>
            </DynamicText>

            <DynamicText delay={skipAnimations ? 0 : 800}>
              <p className="text-2xl sm:text-3xl md:text-4xl text-red-600 font-bold tracking-[0.05em] drop-shadow-lg">
                REBA McENTIRE
              </p>
            </DynamicText>

            <DynamicText delay={skipAnimations ? 0 : 1600}>
              <p className="text-2xl sm:text-3xl md:text-4xl text-white font-light tracking-[0.05em] drop-shadow-lg">
                PROOF THAT AUTHENTICITY NEVER FADES
              </p>
            </DynamicText>
          </motion.div>
        </div>
      </CinematicSlide>
    )
  },
  {
    id: 'down-home',
    title: 'Down Home',
    component: ({ skipAnimations }) => (
      <CinematicSlide className="bg-black">
        {/* Background Image */}
        <div className="absolute inset-0">
          <motion.div
            initial={skipAnimations ? { opacity: 1 } : { opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
            className="relative h-full"
          >
            <img 
              src="/images/Family/photo-1476067897447-d0c5df27b5df-scaled.webp"
              alt="Mountain Road Background"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/30 to-black/60" />
          </motion.div>
        </div>

        <div className="relative z-10 flex flex-col justify-center items-center h-full">
          {/* Logo Container */}
          <motion.div
            initial={skipAnimations ? {} : { opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1.2, delay: 0.5 }}
            className="w-full max-w-3xl px-8"
          >
            <img 
              src="/images/Family/middh.webp"
              alt="Down Home Logo"
              className="w-full h-auto"
            />
          </motion.div>
        </div>
      </CinematicSlide>
    )
  },
  {
    id: 'down-home-intro-2',
    title: 'Down Home Introduction 2',
    component: ({ skipAnimations }) => (
      <CinematicSlide className="bg-black">
        <div className="absolute inset-0">
          {/* Background with diagonal split */}
          <div className="absolute inset-0">
            {/* Dark background */}
            <div className="absolute inset-0 bg-[#1A1A1A]" />
            
            {/* Image section with diagonal clip - Desktop */}
            <div 
              className="absolute inset-0 hidden md:block" 
              style={{
                clipPath: 'polygon(42% 0, 100% 0, 100% 100%, 32% 100%)'
              }}
            >
              <img 
                src="/images/people/tm.jpg"
                alt="Tim McGraw Portrait"
                className="w-full h-full object-cover"
              />
            </div>
          </div>

          {/* Content */}
          <div className="absolute inset-0 flex flex-col md:flex-row font-avenir">
            {/* Left Section - Logo and Text */}
            <div className="w-full md:w-[42%] flex items-center justify-center bg-[#1A1A1A] md:bg-transparent z-10">
              <motion.div
                initial={skipAnimations ? {} : { opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                className="w-full max-w-[90%] space-y-8 sm:space-y-12 md:space-y-16 p-6 sm:p-8 md:p-12 text-center md:text-left"
              >
                {/* Logo */}
                <div className="w-full max-w-[280px] sm:max-w-[320px] md:max-w-[400px] mx-auto">
                  <img 
                    src="/images/Family/middh.webp"
                    alt="Down Home Logo"
                    className="w-full h-auto"
                  />
                </div>

                {/* Text */}
                <div className="space-y-4 sm:space-y-6">
                  <p className="text-xl sm:text-2xl md:text-3xl xl:text-4xl 2xl:text-5xl text-white tracking-wide leading-tight font-medium">
                    DOWN HOME IS AN ENTERTAINMENT COMPANY BASED IN NASHVILLE
                  </p>
                  <p className="text-xl sm:text-2xl md:text-3xl xl:text-4xl 2xl:text-5xl text-white tracking-wide leading-tight font-medium">
                    CO-FOUNDED BY TIM McGRAW
                  </p>
                </div>
              </motion.div>
            </div>

            {/* Right Section - Image (Mobile only) */}
            <div className="relative flex-1 h-[40vh] sm:h-[50vh] md:hidden">
              <motion.div
                initial={skipAnimations ? {} : { opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1.5 }}
                className="absolute inset-0"
              >
                <img 
                  src="/images/people/tm.jpg"
                  alt="Tim McGraw Portrait"
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-[#1A1A1A] via-transparent to-transparent" />
              </motion.div>
            </div>
          </div>

          {/* Page Number */}
          <div className="absolute bottom-4 right-4 text-black/60 text-sm hidden md:block">
            2
          </div>

          {/* Down Home Small Logo */}
          <div className="absolute bottom-4 left-4 hidden md:block">
            <img 
              src="/images/Family/middh.webp"
              alt="Down Home Logo Small"
              className="h-6 opacity-60"
            />
          </div>
        </div>
      </CinematicSlide>
    )
  },
  {
    id: 'skydance',
    title: 'Skydance Media',
    component: ({ skipAnimations }) => (
      <SkydanceSlide skipAnimations={skipAnimations} />
    )
  },
  {
    id: 'western-culture',
    title: 'Western Culture Statistics',
    component: ({ skipAnimations }) => (
      <WesternCultureSlide skipAnimations={skipAnimations} />
    )
  },
  {
    id: 'best-of-both',
    title: 'Best of Both',
    component: ({ skipAnimations }) => (
      <BestOfBothSlide skipAnimations={skipAnimations} />
    )
  },
  {
    id: 'rodeo-business',
    title: 'Rodeo Business Stats',
    component: ({ skipAnimations }) => (
      <RodeoBusinessSlide skipAnimations={skipAnimations} />
    )
  },
  {
    id: 'nashville-legacy',
    title: 'Nashville Legacy',
    component: ({ skipAnimations }) => (
      <CinematicSlide className="bg-black">
        <NashvilleLegacySlide skipAnimations={skipAnimations} />
      </CinematicSlide>
    )
  },
  {
    id: 'nashville-rodeo',
    title: 'Nashville Rodeo',
    component: ({ skipAnimations }) => (
      <CinematicSlide className="bg-black">
        <NashvilleRodeoSlide skipAnimations={skipAnimations} />
      </CinematicSlide>
    )
  },
  {
    id: 'rodeo-music',
    title: 'Rodeo & Music',
    component: ({ skipAnimations }) => (
      <CinematicSlide className="bg-black">
        <RodeoMusicSlide skipAnimations={skipAnimations} />
      </CinematicSlide>
    )
  },
  {
    id: 'team',
    title: 'Team',
    component: ({ skipAnimations }) => (
      <CinematicSlide className="bg-black">
        {/* Background gradients */}
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-b from-black via-black/95 to-black" />
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_top,_var(--tw-gradient-stops))] from-red-900/20 via-black to-black" />
        </div>

        <div className="relative z-10 flex flex-col min-h-screen px-4 md:px-8 py-6 md:py-12 pb-24 overflow-y-auto">
          {/* Mobile Grid Layout */}
          <div className="md:hidden grid grid-cols-2 gap-3">
            <motion.div
              initial={skipAnimations ? {} : { opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              className="col-span-2 bg-black/30 rounded-xl p-3 mb-2 flex items-center justify-center backdrop-blur-sm border border-white/10"
            >
              <h2 className="text-2xl sm:text-3xl text-white font-bold tracking-wide text-center">
                WE'VE GOT THE <span className="text-red-600">TEAM</span><br />TO LEAD IT.
              </h2>
            </motion.div>

            {/* Team members in grid */}
            {[
              {
                name: "TIM McGRAW",
                title: "COUNTRY MUSIC ICON, ACTOR, & CO-FOUNDER OF DOWN HOME",
                image: "/images/people/mcgraw.jpg"
              },
              {
                name: "PATRICK HUMES",
                title: "PRCA COWBOY TURNED REAL ESTATE DEVELOPER & ENTREPRENEUR",
                image: "/images/people/pat.jpg"
              },
              {
                name: "BILL STAPLETON",
                title: "CO-FOUNDER OF AUSTIN CITY LIMITS, LOLLAPALOOZA, & C3 PRESENTS",
                image: "/images/people/bill.jpg"
              },
              {
                name: "BRIAN KAPLAN",
                title: "CO-FOUNDER OF DOWN HOME, MUSIC MARKETING VETERAN",
                image: "/images/people/brian.jpg"
              }
            ].map((member, index) => (
              <motion.div
                key={member.name}
                initial={skipAnimations ? {} : { opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: skipAnimations ? 0 : 0.2 + (index * 0.1) }}
                className="relative aspect-square rounded-lg overflow-hidden"
              >
                <img
                  src={member.image}
                  alt={member.name}
                  className="absolute inset-0 w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black via-black/40 to-transparent" />
                <div className="absolute inset-x-0 bottom-0 p-3 text-center">
                  <h3 className="text-lg font-bold text-white tracking-wide mb-1">
                    {member.name}
                  </h3>
                  <p className="text-xs text-white/80 tracking-wide leading-tight">
                    {member.title}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>

          {/* Desktop Layout */}
          <div className="hidden md:flex flex-col h-full max-h-[calc(100vh-120px)]">
            <motion.div
              initial={skipAnimations ? {} : { opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              className="text-center mb-16"
            >
              <h2 className="text-5xl text-white font-bold tracking-wide">
                WE'VE GOT THE <span className="text-red-600">TEAM</span> TO LEAD IT.
              </h2>
            </motion.div>

            <div className="grid grid-cols-4 gap-8 max-w-7xl mx-auto">
              {[
                {
                  name: "TIM McGRAW",
                  title: "COUNTRY MUSIC ICON, ACTOR, & CO-FOUNDER OF DOWN HOME",
                  image: "/images/people/mcgraw.jpg"
                },
                {
                  name: "PATRICK HUMES",
                  title: "PRCA COWBOY TURNED REAL ESTATE DEVELOPER & ENTREPRENEUR",
                  image: "/images/people/pat.jpg"
                },
                {
                  name: "BILL STAPLETON",
                  title: "CO-FOUNDER OF AUSTIN CITY LIMITS, LOLLAPALOOZA, & C3 PRESENTS",
                  image: "/images/people/bill.jpg"
                },
                {
                  name: "BRIAN KAPLAN",
                  title: "CO-FOUNDER OF DOWN HOME, MUSIC MARKETING VETERAN",
                  image: "/images/people/brian.jpg"
                }
              ].map((member, index) => (
                <motion.div
                  key={member.name}
                  initial={skipAnimations ? {} : { opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: skipAnimations ? 0 : 0.2 + (index * 0.1) }}
                  className="flex flex-col items-center text-center"
                >
                  <div className="relative w-full aspect-[3/4] mb-4 overflow-hidden rounded-lg">
                    <img
                      src={member.image}
                      alt={member.name}
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/20 to-transparent" />
                  </div>
                  <h3 className="text-xl text-white font-bold tracking-wide mb-2">
                    {member.name}
                  </h3>
                  <p className="text-sm text-white/80 tracking-wide leading-snug">
                    {member.title}
                  </p>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </CinematicSlide>
    )
  },
  {
    id: 'hype',
    title: 'Hype',
    component: ({ skipAnimations }) => (
      <CinematicSlide className="bg-black">
        {/* Background Image */}
        <div className="absolute inset-0">
          <motion.div
            initial={skipAnimations ? { opacity: 1 } : { opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
            className="relative h-full"
          >
            <img 
              src="/images/people/ragdoll.jpg"
              alt="Ragdoll Netflix"
              className="w-full h-full object-cover"
              style={{ objectPosition: '50% 40%' }}
            />
            {/* Mobile: Lighter overlay */}
            <div className="absolute inset-0 md:hidden bg-black/70" />
            {/* Desktop: Original overlay */}
            <div className="absolute inset-0 hidden md:block bg-gradient-to-b from-black/60 via-transparent to-black/60" />
          </motion.div>
        </div>

        <div className="relative z-10 flex flex-col justify-between h-full">
          {/* Mobile Layout */}
          <div className="md:hidden flex flex-col justify-center h-full px-4 py-8 text-center">
            <motion.div
              initial={skipAnimations ? {} : { opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.5 }}
              className="space-y-8"
            >
              <h2 className="text-4xl text-white font-bold tracking-wider">
                THE <span className="text-red-600">HYPE</span><br />
                TO DRIVE IT
              </h2>

              <div className="flex flex-col items-center space-y-4">
                <motion.div
                  initial={skipAnimations ? {} : { opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1, delay: 1 }}
                  className="bg-black/40 backdrop-blur-sm rounded-xl p-6 space-y-3"
                >
                  <span className="text-3xl font-bold text-red-600 tracking-wider block">
                    RAGDOLL
                  </span>
                  <span className="text-xl font-bold text-white tracking-wide block">
                    COMING SOON TO
                  </span>
                  <img 
                    src="/images/logo/netflix.png"
                    alt="Netflix"
                    className="h-12 mx-auto"
                  />
                </motion.div>
              </div>
            </motion.div>
          </div>

          {/* Desktop Layout */}
          <div className="hidden md:flex flex-col justify-between h-full py-16 px-8">
            <motion.div
              initial={skipAnimations ? {} : { opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.5 }}
              className="text-center"
            >
              <h2 className="text-5xl md:text-7xl text-white font-bold tracking-wider">
                THE <span className="text-red-600">HYPE</span> TO DRIVE IT
              </h2>
            </motion.div>

            <div className="flex flex-col items-center space-y-4">
              <motion.div
                initial={skipAnimations ? {} : { opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, delay: 1 }}
                className="flex items-center gap-4"
              >
                <span className="text-4xl md:text-6xl font-bold text-red-600 tracking-wider font-avenir">
                  RAGDOLL
                </span>
                <span className="text-2xl md:text-3xl font-bold text-white tracking-wide font-avenir">
                  COMING SOON TO
                </span>
                <img 
                  src="/images/logo/netflix.png"
                  alt="Netflix"
                  className="h-16 md:h-20 lg:h-24 object-contain"
                />
              </motion.div>
            </div>
          </div>
        </div>
      </CinematicSlide>
    )
  },
  {
    id: 'partnership-opportunities',
    title: 'Partnership Opportunities',
    component: ({ skipAnimations }) => (
      <CinematicSlide className="bg-black">
        {/* Background with subtle gradient */}
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-b from-black via-black/95 to-black" />
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_top,_var(--tw-gradient-stops))] from-red-900/20 via-black to-black" />
        </div>

        <div className="relative z-10 flex flex-col justify-center min-h-screen px-4 md:px-8 py-8">
          {/* Mobile Layout */}
          <div className="md:hidden space-y-8">
            <motion.div
              initial={skipAnimations ? {} : { opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              className="space-y-8 text-center"
            >
              <div className="space-y-6">
                <p className="text-xl sm:text-2xl text-white/90 font-light leading-relaxed">
                  We know this event can only reach its full potential if it is embraced by the heroes of our industry and the community that admires them.
                </p>
              </div>

              <div className="space-y-4">
                <p className="text-xl sm:text-2xl text-white/90 font-light leading-relaxed">
                  That is why we have hand selected
                </p>
                <p className="text-2xl sm:text-3xl text-red-600 font-bold">
                  REBA
                </p>
                <p className="text-xl sm:text-2xl text-white/90 font-light leading-relaxed">
                  to be a partner in the Rodeo and to join us in making the Rodeo a staple event in Nashville.
                </p>
              </div>

              <div className="bg-red-600/10 rounded-xl p-6 backdrop-blur-sm">
                <p className="text-xl sm:text-2xl text-red-600 font-bold leading-relaxed">
                  We are offering an equal 2.5% equity in the Music City Rodeo.
                </p>
              </div>
            </motion.div>
          </div>

          {/* Desktop Layout */}
          <div className="hidden md:block max-w-5xl mx-auto">
            <motion.div
              initial={skipAnimations ? {} : { opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              className="space-y-12 text-center"
            >
              <div className="space-y-8">
                <p className="text-3xl lg:text-4xl text-white/90 font-light leading-relaxed">
                  We know this event can only reach its full potential if it is embraced by the heroes of our industry and the community that admires them.
                </p>
              </div>

              <div className="space-y-6">
                <p className="text-3xl lg:text-4xl text-white/90 font-light leading-relaxed">
                  That is why we have hand selected
                </p>
                <p className="text-4xl lg:text-5xl text-red-600 font-bold tracking-wide">
                  REBA
                </p>
                <p className="text-3xl lg:text-4xl text-white/90 font-light leading-relaxed">
                  to be a partner in the Rodeo and to join us in making the Rodeo a staple event in Nashville.
                </p>
              </div>

              <div className="bg-red-600/10 rounded-xl p-8 backdrop-blur-sm max-w-3xl mx-auto">
                <p className="text-3xl lg:text-4xl text-red-600 font-bold leading-relaxed">
                  We are offering an equal 2.5% equity in the Music City Rodeo.
                </p>
              </div>
            </motion.div>
          </div>
        </div>
      </CinematicSlide>
    )
  },
  {
    id: 'offering',
    title: 'Offering',
    component: ({ skipAnimations }) => (
      <CinematicSlide className="bg-black">
        <div className="flex flex-col justify-center min-h-screen px-4 md:px-8 py-12">
          <motion.div
            initial={skipAnimations ? { opacity: 1 } : { opacity: 0 }}
            animate={{ opacity: 1 }}
            className="max-w-6xl mx-auto w-full"
          >
            {/* Header */}
            <DynamicText skipAnimation={skipAnimations}>
              <div className="text-center mb-12">
                <h2 className="text-3xl md:text-5xl text-red-600 font-bold tracking-wide mb-3">
                  THE OPPORTUNITY
                </h2>
                <p className="text-xl md:text-2xl text-white/80 tracking-wide">
                  Join Us in Making History
                </p>
              </div>
            </DynamicText>

            {/* Main Content Grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
              {/* Market Context */}
              <DynamicText delay={skipAnimations ? 0 : 400}>
                <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10">
                  <div className="flex flex-col h-full">
                    <div className="flex items-center gap-4 mb-6">
                      <Star className="w-12 h-12 text-red-600" />
                      <h3 className="text-2xl text-white font-medium">Market Context</h3>
                    </div>
                    <div className="space-y-6 flex-grow">
                      <div className="text-center py-4 border-b border-white/10">
                        <p className="text-4xl md:text-5xl text-red-600 font-bold mb-2">$330M</p>
                        <p className="text-lg text-white/80">Rodeo Houston Annual Revenue</p>
                      </div>
                      <div className="text-center py-4">
                        <p className="text-4xl md:text-5xl text-red-600 font-bold mb-2">$180M+</p>
                        <p className="text-lg text-white/80">MCR Projected Revenue by Year 5</p>
                      </div>
                    </div>
                  </div>
                </div>
              </DynamicText>

              {/* Ownership Details */}
              <DynamicText delay={skipAnimations ? 0 : 600}>
                <div className="bg-red-600/10 backdrop-blur-sm rounded-xl p-8 border border-red-600/20">
                  <div className="flex flex-col h-full">
                    <div className="flex items-center gap-4 mb-6">
                      <Award className="w-12 h-12 text-red-600" />
                      <h3 className="text-2xl text-white font-medium">Ownership Stake</h3>
                    </div>
                    <div className="space-y-6 flex-grow">
                      <div className="text-center">
                        <p className="text-5xl md:text-6xl text-red-600 font-bold mb-4">2.5%</p>
                        <p className="text-xl text-white/90 mb-2">Ownership in Music City Rodeo</p>
                        <p className="text-lg text-white/70">Currently valued at <span className="text-red-600 font-bold">$20M+</span></p>
                      </div>
                      <div className="text-center pt-6 border-t border-red-600/20">
                        <p className="text-lg text-white/80 italic leading-relaxed">
                          "Be part of a legacy that bridges the worlds of country music and rodeo"
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </DynamicText>
            </div>

            {/* Bottom Message */}
            <DynamicText delay={skipAnimations ? 0 : 800}>
              <div className="text-center max-w-2xl mx-auto">
                <motion.div
                  initial={skipAnimations ? {} : { y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.2 }}
                  className="bg-white/5 backdrop-blur-sm rounded-lg p-6"
                >
                  <p className="text-xl md:text-2xl text-white/90 font-medium leading-relaxed">
                    When we succeed, <span className="text-red-600">you succeed</span>. Join us in creating the next chapter of Nashville's entertainment legacy.
                  </p>
                </motion.div>
              </div>
            </DynamicText>
          </motion.div>
        </div>
      </CinematicSlide>
    )
  },
  {
    id: 'ownership-value',
    title: 'Ownership Value',
    component: ({ skipAnimations }) => (
      <CinematicSlide className="bg-black">
        <div className="flex flex-col justify-center items-center h-full px-4 md:px-8">
          <motion.div
            initial={skipAnimations ? { opacity: 1 } : { opacity: 0 }}
            animate={{ opacity: 1 }}
            className="max-w-4xl mx-auto text-center w-full"
          >
            {/* Header */}
            <DynamicText skipAnimation={skipAnimations}>
              <h2 className="text-2xl md:text-4xl text-red-600 font-bold tracking-wide mb-8">
                2.5% OWNERSHIP VALUE GROWTH
              </h2>
            </DynamicText>

            {/* Mobile Layout */}
            <div className="md:hidden space-y-6">
              {/* Current Value Card */}
              <DynamicText delay={skipAnimations ? 0 : 400}>
                <div className="bg-white/5 backdrop-blur-sm rounded-lg p-6 border border-white/10">
                  <p className="text-lg text-white/80 mb-2">Value Today</p>
                  <p className="text-3xl text-red-600 font-bold mb-2">
                    $500,000
                  </p>
                  <p className="text-sm text-white/60">
                    2.5% of $20M current valuation
                  </p>
                </div>
              </DynamicText>

              {/* Future Value Card */}
              <DynamicText delay={skipAnimations ? 0 : 600}>
                <div className="bg-white/5 backdrop-blur-sm rounded-lg p-6 border border-white/10">
                  <p className="text-lg text-white/80 mb-2">Projected Value in 2029</p>
                  <p className="text-3xl text-red-600 font-bold mb-2">
                    $23.5M+
                  </p>
                  <p className="text-sm text-white/60">
                    2.5% of $940M projected valuation
                  </p>
                </div>
              </DynamicText>

              {/* Growth Visualization - Mobile */}
              <div className="bg-white/5 backdrop-blur-sm rounded-lg p-4">
                <RevenueProjections skipAnimations={skipAnimations} isMobile={true} />
              </div>
            </div>

            {/* Desktop Layout */}
            <div className="hidden md:block">
              <div className="grid grid-cols-2 gap-8 mb-12">
                <DynamicText delay={skipAnimations ? 0 : 400}>
                  <div className="bg-white/5 backdrop-blur-sm rounded-lg p-8 border border-white/10">
                    <p className="text-xl text-white/80 mb-2">Value Today</p>
                    <p className="text-4xl md:text-5xl text-red-600 font-bold mb-2">
                      $500,000
                    </p>
                    <p className="text-sm text-white/60">
                      2.5% of $20M current valuation
                    </p>
                  </div>
                </DynamicText>

                <DynamicText delay={skipAnimations ? 0 : 600}>
                  <div className="bg-white/5 backdrop-blur-sm rounded-lg p-8 border border-white/10">
                    <p className="text-xl text-white/80 mb-2">Projected Value in 2029</p>
                    <p className="text-4xl md:text-5xl text-red-600 font-bold mb-2">
                      $23.5M+
                    </p>
                    <p className="text-sm text-white/60">
                      2.5% of $940M projected valuation
                    </p>
                  </div>
                </DynamicText>
              </div>

              {/* Growth Visualization - Desktop */}
              <div className="bg-white/5 backdrop-blur-sm rounded-lg p-8">
                <RevenueProjections skipAnimations={skipAnimations} />
              </div>
            </div>
          </motion.div>
        </div>
      </CinematicSlide>
    )
  },
  {
    id: 'growth-timeline',
    title: 'Growth Timeline',
    totalSubSteps: 4,  // Important for navigation
    component: ({ skipAnimations, currentSubStep }) => (
      <CinematicSlide className="bg-black">
        <GrowthTimeline skipAnimations={skipAnimations} currentSubStep={currentSubStep} />
      </CinematicSlide>
    )
  },
  {
    id: 'expansion-plans',
    title: 'Expansion Plans',
    component: ({ skipAnimations }) => (
      <CinematicSlide className="bg-black">
        <div className="flex flex-col h-full p-6 md:p-8 gap-6">
          {/* Header Section */}
          <motion.div
            initial={skipAnimations ? { opacity: 1 } : { opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-center"
          >
            <div className="bg-black/40 backdrop-blur-sm py-3 px-6 inline-block rounded-xl border border-zinc-800/30">
              <h2 className="text-3xl sm:text-4xl md:text-5xl text-red-600 font-bold">
                EXPANSION PLANS
              </h2>
              <div className="h-px bg-gradient-to-r from-transparent via-zinc-500/30 to-transparent my-2" />
              <p className="text-xl sm:text-2xl md:text-3xl text-white font-light tracking-wider">
                THE SOUTHERN SWING
              </p>
            </div>
          </motion.div>

          {/* Main Content Grid */}
          <div className="flex-1 grid grid-cols-12 gap-6">
            {/* Left Side - Map */}
            <div className="col-span-8">
              <motion.div
                initial={skipAnimations ? {} : { opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                className="h-full bg-zinc-900/50 backdrop-blur-sm rounded-xl overflow-hidden border border-zinc-800/30"
              >
                <div className="relative w-full h-full">
                  <USMap />
                </div>
              </motion.div>
            </div>

            {/* Right Side - Timeline Cards */}
            <div className="col-span-4 flex flex-col gap-4">
              {[
                {
                  year: "YEAR 3",
                  cities: "Charlotte",
                  color: "text-red-600",
                  bgColor: "bg-zinc-900/90"
                },
                {
                  year: "YEAR 4",
                  cities: "Tampa • Knoxville",
                  color: "text-white",
                  bgColor: "bg-zinc-900/70"
                },
                {
                  year: "YEAR 5",
                  cities: "New Orleans +",
                  color: "text-white/90",
                  bgColor: "bg-zinc-900/50"
                }
              ].map((phase, index) => (
                <motion.div
                  key={phase.year}
                  initial={skipAnimations ? {} : { opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.2 }}
                  className={`${phase.bgColor} backdrop-blur-sm rounded-xl border border-zinc-800/30 flex-1`}
                >
                  <div className="p-6 text-center space-y-3">
                    <h3 className={`text-2xl md:text-3xl font-bold ${phase.color}`}>
                      {phase.year}
                    </h3>
                    <div className="h-px bg-gradient-to-r from-transparent via-zinc-500/30 to-transparent" />
                    <p className="text-lg md:text-xl text-white/90 font-medium tracking-wide">
                      {phase.cities}
                    </p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </CinematicSlide>
    )
  },
  {
    id: 'financial-model',
    title: 'Financial Model',
    component: ({ skipAnimations }) => (
      <FinancialModelSlide skipAnimations={skipAnimations} />
    )
  },
  {
    id: 'personal-letter',
    title: 'Personal Letter',
    component: ({ skipAnimations }) => (
      <PersonalLetterSlide />
    )
  },
  {
    id: 'the-ask',
    title: 'The Ask',
    component: ({ skipAnimations }) => (
      <CinematicSlide className="bg-black">
        {/* Background with gradient and hero image */}
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-b from-black via-black/95 to-black" />
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_top,_var(--tw-gradient-stops))] from-red-900/20 via-black to-black" />
          
          {/* Hero Image with Spotlight Effect */}
          <div className="absolute inset-0 opacity-15">
            <img 
              src="/images/arena/arena-spotlight.jpg"
              alt="Rodeo Arena"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black via-black/60 to-transparent" />
          </div>
        </div>

        <div className="relative z-10 flex flex-col min-h-screen">
          {/* Mobile Layout */}
          <div className="md:hidden flex flex-col h-full px-4 py-6 justify-center">
            <motion.div
              initial={skipAnimations ? {} : { opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              className="text-center mb-6"
            >
              <h2 className="text-3xl text-white font-bold tracking-wider drop-shadow-lg mb-2">
                JOIN US IN CREATING A <span className="text-red-600">LEGACY</span>
              </h2>
              <p className="text-lg text-white/90 tracking-wide">
                Be a part of Nashville's First PRCA Rodeo
              </p>
            </motion.div>

            <div className="space-y-4 overflow-y-auto">
              {/* Support Section */}
              <motion.div
                initial={skipAnimations ? {} : { opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3 }}
                className="bg-white/5 backdrop-blur-sm rounded-xl p-4 text-center"
              >
                <h3 className="text-lg text-red-600 font-bold mb-3">Support the Announcement</h3>
                <ul className="text-white/90 space-y-2.5 text-sm inline-block text-left">
                  <li className="flex items-start gap-2">
                    <span className="text-red-600 mt-1">•</span>
                    <span>Provide a quote for the press release</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <span className="text-red-600 mt-1">•</span>
                    <span>Share the event on your social media</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <span className="text-red-600 mt-1">•</span>
                    <span>Participate in a short on-camera segment</span>
                  </li>
                </ul>
              </motion.div>

              {/* Opening Night Section */}
              <motion.div
                initial={skipAnimations ? {} : { opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
                className="bg-red-600/10 backdrop-blur-sm rounded-xl p-4 text-center"
              >
                <h3 className="text-lg text-white font-bold mb-3">Opening Night</h3>
                <div className="space-y-2">
                  <p className="text-white/90 text-sm leading-relaxed">
                    We'd love to have you perform on opening night. If not possible, your presence would mean the world.
                  </p>
                  <p className="text-white/80 text-sm italic">
                    If a performance isn't feasible this year, we'd love for you to commit to performing at the Rodeo within the first three years (at cost).
                  </p>
                </div>
              </motion.div>

              {/* Legacy Section */}
              <motion.div
                initial={skipAnimations ? {} : { opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5 }}
                className="bg-white/5 backdrop-blur-sm rounded-xl p-4 text-center"
              >
                <h3 className="text-lg text-red-600 font-bold mb-3">Show Your Support</h3>
                <p className="text-white/90 text-sm leading-relaxed">
                  Be part of future Music City Rodeo events — help us create a legacy of resilience, music, and rodeo.
                </p>
              </motion.div>
            </div>
          </div>

          {/* Desktop Layout */}
          <div className="hidden md:flex flex-col h-screen px-8 py-8 justify-center">
            <motion.div
              initial={skipAnimations ? {} : { opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              className="text-center mb-10"
            >
              <h2 className="text-5xl lg:text-6xl text-white font-bold tracking-wider mb-4">
                JOIN US IN CREATING A <span className="text-red-600">LEGACY</span>
              </h2>
              <p className="text-2xl lg:text-3xl text-white/90 tracking-wide">
                Be a part of Nashville's First PRCA Rodeo
              </p>
            </motion.div>

            <div className="grid grid-cols-2 gap-6 max-w-6xl mx-auto w-full">
              <div className="space-y-6">
                {/* Support Section */}
                <motion.div
                  initial={skipAnimations ? {} : { opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.3 }}
                  className="bg-white/5 backdrop-blur-sm rounded-xl p-6 h-[calc(50%-12px)] text-center"
                >
                  <h3 className="text-2xl text-red-600 font-bold mb-4">Support the Announcement</h3>
                  <ul className="text-white/90 space-y-3 text-lg inline-block text-left">
                    <li className="flex items-start gap-3">
                      <span className="text-red-600 mt-1.5">•</span>
                      <span>Provide a quote for the press release</span>
                    </li>
                    <li className="flex items-start gap-3">
                      <span className="text-red-600 mt-1.5">•</span>
                      <span>Share the event on your social media</span>
                    </li>
                    <li className="flex items-start gap-3">
                      <span className="text-red-600 mt-1.5">•</span>
                      <span>Participate in a short on-camera segment</span>
                    </li>
                  </ul>
                </motion.div>

                {/* Legacy Section */}
                <motion.div
                  initial={skipAnimations ? {} : { opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.4 }}
                  className="bg-white/5 backdrop-blur-sm rounded-xl p-6 h-[calc(50%-12px)] text-center"
                >
                  <h3 className="text-2xl text-red-600 font-bold mb-4">Show Your Support</h3>
                  <p className="text-white/90 text-lg leading-relaxed">
                    Be part of future Music City Rodeo events — help us create a legacy of resilience, music, and rodeo.
                  </p>
                </motion.div>
              </div>

              {/* Opening Night Section */}
              <motion.div
                initial={skipAnimations ? {} : { opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.5 }}
                className="bg-red-600/10 backdrop-blur-sm rounded-xl p-6 h-full text-center"
              >
                <h3 className="text-2xl text-white font-bold mb-4">Opening Night</h3>
                <div className="space-y-4">
                  <p className="text-white/90 text-sm leading-relaxed">
                    We'd love to have you perform on opening night. If not possible, your presence would mean the world.
                  </p>
                  <p className="text-white/80 text-sm italic">
                    If a performance isn't feasible this year, we'd love for you to commit to performing at the Rodeo within the first three years (at cost).
                  </p>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </CinematicSlide>
    )
  }
];

// Create mapping of desired order
const slideOrder = [
  'opening',
  'except', 
  'event-intro',
  'logo-reveal',
  'invitation',
  'oklahoma-legacy',
  'clark-legacy',
  'young-reba',
  'nfr-1974',
  'homecoming',
  'authenticity',
  'down-home',
  'down-home-intro-2',
  'skydance',
  'western-culture',
  'best-of-both',
  'rodeo-business', 
  'nashville-legacy',
  'nashville-rodeo',
  'rodeo-music',
  'team',
  'hype',
  'partnership-opportunities',
  'the-ask',
  'offering',
  'ownership-value',
  'growth-timeline',
  'expansion-plans',
  'financial-model',
  'personal-letter'
];

// Reorder slides based on the mapping
export const slides = slideOrder.map(id => 
  allSlides.find(slide => slide.id === id)
);

export default slides;
