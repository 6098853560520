import React, { useEffect } from 'react';
import PresentationDeck from './components/PresentationDeck';
import './styles/globals.css';
import './styles.css';

export default function App() {
  useEffect(() => {
    // Keep the security measures for preventing keyboard shortcuts and context menu
    const preventDefaults = (e) => {
      e.preventDefault();
      return false;
    };

    const preventKeyboardShortcuts = (e) => {
      if ((e.ctrlKey || e.metaKey) && 
          (e.key === 'p' || e.key === 's' || e.key === 'u' || 
           e.key === 'c' || e.key === 'v')) {
        e.preventDefault();
        return false;
      }
    };

    document.addEventListener('contextmenu', preventDefaults);
    document.addEventListener('keydown', preventKeyboardShortcuts);

    return () => {
      document.removeEventListener('contextmenu', preventDefaults);
      document.removeEventListener('keydown', preventKeyboardShortcuts);
    };
  }, []);

  return (
    <div className="font-sans">
      <PresentationDeck />
    </div>
  );
} 